import { defineStore } from 'pinia';

interface TooltipState {
    tooltips: {
        leaderboardBits: boolean,
        leaderboardDegree: boolean,
        leaderboardFriends: boolean,
        inviteFriendsBonusesExplanation: boolean,
        inviteFriendsBonusTierExplanation: boolean,
        inviteFriendsLockedTier: boolean,
        share: boolean,
        hearts: boolean,
        wallet: boolean,
        badges: boolean,
        chain: boolean,
        score: boolean,
        primaryWallet: boolean,
        countdown: boolean,
        maxReward: boolean,
        completedRound: boolean,
        profile: boolean,
        friendInviteExplanation: boolean,
        certificateDescription: boolean,
        streakTippyEarnMore: boolean,
        streakBreaks: boolean,
        streakItem: boolean,
        unlockStudio: boolean,
        unlockStudioStepCompleted: boolean,
        airdropPrizePool: boolean,
        airdropPrizes: boolean,
        airdropTopLeaders: boolean,
        airdropSeasonBits: boolean,
        guildFullBadge: boolean,
        guildBadge: boolean,
        gameReferralScreenBonus: boolean,
        bonusSubtitleExplanation: boolean,
        bonusTaskCompleted: boolean,
        ownedGuildRemove: boolean,
    },
}

interface Tooltips {
    [tooltip: string]: boolean;
}

export const useTooltipStore = defineStore({
    id: 'tooltipStore',
    state: (): TooltipState => ({
        tooltips: {
            leaderboardBits: false,
            leaderboardDegree: false,
            leaderboardFriends: false,
            inviteFriendsBonusesExplanation: false,
            inviteFriendsBonusTierExplanation: false,
            inviteFriendsLockedTier: false,
            share: false,
            hearts: false,
            wallet: false,
            badges: false,
            chain: false,
            score: false,
            primaryWallet: false,
            countdown: false,
            maxReward: false,
            completedRound: false,
            profile: false,
            friendInviteExplanation: false,
            certificateDescription: false,
            streakTippyEarnMore: false,
            streakBreaks: false,
            streakItem: false,
            unlockStudio: false,
            unlockStudioStepCompleted: false,
            airdropPrizePool: false,
            airdropPrizes: false,
            airdropTopLeaders: false,
            airdropSeasonBits: false,
            guildFullBadge: false,
            guildBadge: false,
            gameReferralScreenBonus: false,
            bonusSubtitleExplanation: false,
            bonusTaskCompleted: false,
            ownedGuildRemove: false,
        },
    }),
    actions: {
        hideAllTooltips(value: boolean): void {
            if (!value) {
                return;
            }

            const tooltips: Tooltips = this.tooltips;
            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const tooltip in tooltips) {
                tooltips[tooltip] = false;
            }
        },
        setLeaderboardBitsTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.leaderboardBits = value;
        },
        setLeaderboardDegreeTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.leaderboardDegree = value;
        },
        setLeaderboardFriendsTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.leaderboardFriends = value;
        },
        setInviteFriendsBonusesExplanationTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.inviteFriendsBonusesExplanation = value;
        },
        setInviteFriendsBonusTierExplanationTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.inviteFriendsBonusTierExplanation = value;
        },
        setInviteFriendsLockedTierTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.inviteFriendsLockedTier = value;
        },
        setShareTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.share = value;
        },
        setHeartsTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.hearts = value;
        },
        setWalletTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.wallet = value;
        },
        setBadgesTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.badges = value;
        },
        setChainTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.chain = value;
        },
        setScoreTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.score = value;
        },
        setPrimaryWalletTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.primaryWallet = value;
        },
        setCountdownTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.countdown = value;
        },
        setMaxRewardTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.maxReward = value;
        },
        setCompletedRoundTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.completedRound = value;
        },
        setProfileTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.profile = value;
        },
        setCertificateDescriptionTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.certificateDescription = value;
        },
        setStreakTippyEarnMoreTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.streakTippyEarnMore = value;
        },
        setStreakBreaksTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.streakBreaks = value;
        },
        setFriendInviteExplanationTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.friendInviteExplanation = value;
        },
        setStreakItemTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.streakItem = value;
        },
        setUnlockStudioTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.unlockStudio = value;
        },
        setUnlockStudioStepCompletedTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.unlockStudioStepCompleted = value;
        },
        setAirdropPrizePoolTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.airdropPrizePool = value;
        },
        setAirdropPrizesTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.airdropPrizes = value;
        },
        setAirdropTopLeadersTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.airdropTopLeaders = value;
        },
        setAirdropSeasonBitsTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.airdropSeasonBits = value;
        },
        setGuildFullBadgeTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.guildFullBadge = value;
        },
        setGuildBadgeTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.guildBadge = value;
        },
        setGameReferralScreenBonusTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.gameReferralScreenBonus = value;
        },
        setBonusSubtitleExplanationTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.bonusSubtitleExplanation = value;
        },
        setBonusTaskCompletedTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.bonusTaskCompleted = value;
        },
        setOwnedGuildRemoveTooltip(value: boolean): void {
            this.hideAllTooltips(value);
            this.tooltips.ownedGuildRemove = value;
        },
    },
});
